<template>
  <div>
    <div class="row">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 order-lg-1 order-xl-1">
        <KTPortlet
          v-bind:class="'kt-portlet--height-fluid'"
          v-bind="{ bodyFit: true }"
        >
          <template v-slot:body>
            <WidgetRecentNominations></WidgetRecentNominations>
          </template>
        </KTPortlet>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 order-lg-2 order-xl-2">
        <!--Begin::Recent Activities-->
        <!--<KTPortlet-->
        <!--v-bind:class="'kt-portlet--height-fluid'"-->
          <!--v-bind:title="'Recent Suveryor`s Attendance'">-->
          <!--<template v-slot:body>-->
            <!--Begin::Timeline 3 -->
            <!--<RecentSurveyorAttendance v-bind:datasrc="recent_surveyor_attendance"></RecentSurveyorAttendance>-->
            <!--End::Timeline 3 -->
          <!--</template>-->
        <!--</KTPortlet>-->
        <!--End::Recent Activities-->
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 order-lg-3 order-xl-3">
        <!--Begin::Recent Activities-->
        <!--<KTPortlet 
          v-bind:class="'kt-portlet--height-fluid'"
          v-bind:title="'Recent Activities'">
          <template v-slot:body>-->
            <!--Begin::Timeline 3 -->
            <!--<WidgetRecentActivities v-bind:datasrc="widget_recent_activities"></WidgetRecentActivities>-->
            <!--End::Timeline 3 -->
          <!--</template>-->
        <!--</KTPortlet>-->
        <!--End::Recent Activities-->
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
//import ContextMenu1 from "@/views/partials/widgets/ContextMenu1.vue";
//import Widget5 from "@/views/partials/widgets/Widget5.vue";
import WidgetRecentNominations from "@/views/partials/widgets/Widget_RecentNominations.vue";
//import Widget17 from "@/views/partials/widgets/Widget17.vue";
//import Widget20_1 from "@/views/partials/widgets/Widget20_Inbound.vue";
//import Widget20_2 from "@/views/partials/widgets/Widget20_Outbound.vue";
//import WidgetRecentActivities from "@/views/partials/widgets/Widget_RecentActivities.vue";
//import RecentSurveyorAttendance from "@/views/partials/widgets/RecentSurveyorAttendance.vue";

/**
 * Sample widgets data source
 */
import widget_recent_activities from "@/common/mock/widget-recent-activities.json";
//import recent_surveyor_attendance from "@/common/mock/recent_surveyor_attendance.json";
import widget5 from "@/common/mock/widget-5.json";

export default {
  name: "dashboard",
  components: {
    KTPortlet,
    //ContextMenu1,
    //Widget5,
    WidgetRecentNominations,
    //Widget17,
    //Widget20_1,
    //Widget20_2,
    //WidgetRecentActivities,
    //RecentSurveyorAttendance
  },
  data() {
    return {
      widget_recent_activities: widget_recent_activities,
      //recent_surveyor_attendance: recent_surveyor_attendance,
      widget5: widget5,
      tabIndex: 0,
      tabIndex2: 0
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard Surveyor" }]);
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>
